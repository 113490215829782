import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { Drawer, message, notification, PaginationProps, Table } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import qs from 'qs';

import PermissionsService from '../../../services/permissions';
import { ConfirmationModal } from '../../../components/confirmationModal/ConfirmationModal';
import { useUserColumns } from './useUserColumns';
import { useRoleColumns } from './useRoleColumns';
import styles from './styles.module.scss';
import { CreateUserNew } from '../CreateUser/CreateUserNew';
import { CreateRoleNew } from '../CreateRole/CreateRoleNew';

type Props = {
  pageName: string;
  column?: any;
  data?: any;
  loading?: boolean;
};

export const CustomTable = ({ pageName }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [errorCode, setErrorCode] = useState<any>();
  const [userIsActive, setIsUserActive] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [isUserEditOpen, setIsUserEditOpen] = useState<boolean>(false);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const canEdit = JSON.parse(localStorage.getItem('user'))?.role?.permissions[pageName]?.includes('can_edit');
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const { data, isFetching, error } = useQuery(['usersTable', pageName, params], ({ signal }) => {
    switch (pageName) {
      case 'users':
        return PermissionsService.getUsers({ limit: +params.limit || 10, ...params }, { signal });
      case 'roles':
        return PermissionsService.getRoles({ limit: +params.limit || 10, ...params }, { signal });
      default:
        return;
    }
  });

  const getData = useCallback(() => {
    queryClient.refetchQueries('usersTable', { active: true });
  }, [queryClient]);

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  const handleActivateOrDeactivateUser = () => {
    PermissionsService.activateOrDeactivateUser(id, userIsActive)
      .then((res) => {
        getData();
        setOpenModal(false);
      })
      .catch((err) => openNotification(err.message));
  };

  const handleActivateOrDeactivateRole = () => {
    PermissionsService.activateOrDeactivateRole(id, userIsActive)
      .then((res) => {
        getData();
        setOpenModal(false);
      })
      .catch((err) => openNotification(err.message));
  };

  const handleDeleteUser = () => {
    PermissionsService.deleteUserById(selectedData?.id)
      .then((res) => {
        getData();
        setOpenDeleteModal(false);
      })
      .catch((err) => message.error(err.message));
  };

  const handleDeleteRole = () => {
    PermissionsService.deleteRoleById(selectedData?.id)
      .then((res) => {
        getData();
        setOpenDeleteModal(false);
      })
      .catch((err) => message.error(err.message));
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeEditDrawer = () => {
    setIsEditOpen(false);
    setId(null);
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setErrorCode('You do not have permission ');
        setTableData([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
      }
    }
  }, [data, error]);

  const closeUserEditDrawer = () => {
    setIsUserEditOpen(false);
    setId(null);
  };

  const columnsParams = useMemo(
    () => ({ canEdit, setIsUserActive, setId, handleOpenModal, setIsEditOpen, setIsUserEditOpen, setSelectedData }),
    [canEdit, setIsUserActive, setId, handleOpenModal, setIsEditOpen]
  );

  const columnsUserParams = useMemo(
    () => ({
      canEdit,
      setIsUserActive,
      setId,
      handleOpenModal,
      setSelectedData,
      setIsUserEditOpen,
      setOpenDeleteModal,
    }),
    [canEdit, setIsUserActive, setId, handleOpenModal, setSelectedData, setIsUserEditOpen, setOpenDeleteModal]
  );
  const columns = useUserColumns(columnsUserParams);
  const roleColumns = useRoleColumns(columnsParams);

  return (
    <>
      <Table
        columns={pageName === 'users' ? columns : roleColumns}
        dataSource={tableData}
        loading={isFetching}
        // className={styles.ant_table}
        // sticky
        // scroll={{ x: 1300 }}
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        bordered
        size="middle"
      />
      <ConfirmationModal
        open={openModal}
        close={handleCloseModal}
        title={``}
        description={`Are you sure you want to  ${!userIsActive ? 'block' : 'unblock'} with id - ${id} ?`}
        onSubmit={pageName === 'users' ? handleActivateOrDeactivateUser : handleActivateOrDeactivateRole}
      />

      <ConfirmationModal
        open={openDeleteModal}
        close={handleCloseDeleteModal}
        title={``}
        description={`Are you sure you want to delete ${selectedData?.username || selectedData?.name}?`}
        onSubmit={pageName === 'users' ? handleDeleteUser : handleDeleteRole}
      />
      <CreateRoleNew id={id} onCloseEditDrawer={closeEditDrawer} isUserEditOpen={isEditOpen} getData={getData} />

      <Drawer title="Edit user" width="400px" placement="right" onClose={closeUserEditDrawer} open={isUserEditOpen}>
        <CreateUserNew id={id} onCloseEditDrawer={closeUserEditDrawer} />
      </Drawer>
    </>
  );
};
