import { Checkbox, Form } from 'antd';

type Props = {
  isModal: boolean;
};

const defaultStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 12,
};

export const SalesFormItems = ({ isModal }: Props) => {
  return (
    <>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['sales']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;Sales statistics</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['trends']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;Trends</label>
      </Form.Item>
      <Form.Item style={defaultStyles}>
        <Form.Item valuePropName="checked" name={['comparison']} noStyle>
          <Checkbox />
        </Form.Item>
        <label>&nbsp;&nbsp;Comparison</label>
      </Form.Item>
      {/* Sales statistics:
      <Form.Item
        valuePropName="checked"
        name={['sales_statistics_local_company']}
        label=""
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
        &nbsp;View company reports
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['sales_statistics_brand']}
        label=""
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
        &nbsp; View brand reports
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['sales_statistics_barcode']}
        label=""
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
        &nbsp; View SKU reports
      </Form.Item>
      Trends:
      <Form.Item
        valuePropName="checked"
        name={['trends_local_company']}
        label=""
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
        &nbsp; View company reports
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['trends_brand']}
        label=""
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
        &nbsp; View brand reports
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['trends_barcode']}
        label=""
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
        &nbsp; View barcode reports
      </Form.Item>
      Comparison:
      <Form.Item
        valuePropName="checked"
        name={['comparison_local_company']}
        label="- View company reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['comparison_brand']}
        label="- View brand reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name={['comparison_barcode']}
        label="- View barcode reports"
        labelCol={{ span: isModal ? 10 : 5 }}
        labelAlign="left"
      >
        <Checkbox />
      </Form.Item> */}
    </>
  );
};
